<template>
  <div class="warp">
    <el-carousel :interval="5000" arrow="always" height="300">
      <el-carousel-item>
        <div class="banner">
          <img src="~@/assets/img/banner1.jpg" alt="">
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="cont">
      <h2>最新足迹<p></p></h2>
      <div class="well-known-saying">
        <p>记录曾经去过的地方，努力的去追寻，无论在哪里，都要留下足迹； 人生至少要有两次冲动，一为奋不顾身的爱情，一为说走就走的旅行。</p>
      </div>
      <div class="footNav">
        <span v-for="(item,index) of trackNav" :key="item.id" :class="trackActive==index?'footActive':''" @click="currentPage=1;getList(item.id,index)">{{item.className}}</span>
      </div>
      <div class="footprint">
        <el-container class="content_main">
          <el-main class="content_main_context" v-if="trackList.length!=0">
            <div class="artlist">
              <ul>
                <li v-for="item in trackList" :key="item.id">
                  <div class="artlist_point">
                    <p class="artlist_title" @click="toDetail(item.id)">
                      {{ item.name }}
                    </p>
                    <p class="artlist_content" @click="toDetail(item.id)">
                      {{ item.contentTxt }}
                    </p>
                    <p class="artlist_looked">
                      {{ setMoment(item.addTime) }}
                      <span class="look">{{ item.pageviews }}</span>
                      <span class="like">{{ item.likeCount }}</span>
                      <span class="discount">{{ item.commentCount }}</span>
                    </p>
                  </div>
                  <div class="artlist_img" @click="toDetail(item.id)">
                    <img :src="setUrl(item.showImg)" />
                  </div>
                </li>
              </ul>
            </div>
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="10"
                    layout="total,  prev, pager, next, jumper"
                    :total="totalArt"
            >
            </el-pagination>
          </el-main>
          <el-main v-else>
            数据空
          </el-main>
          <div class="right">
            <div class="rightCode">
              <p>
                关注我们<br>
                "<span>鱼海科技</span>"
                公众号
              </p>
              <img src="@/assets/img/code.jpg" alt="">
            </div>
            <div class="advertising"></div>
          </div>
        </el-container>
      </div>
    </div>
  </div>
</template>



<script>
import '@/assets/css/art.scss'
import fetch from '@/services/service'
import moment from 'moment'

export default {
    name: "footprint",
    data() {
        return {
            trackNav:[],
            trackActive:0,
            trackList:[],
            currentPage: 1,
            totalArt: 0,
            classId:""
        }
    },
    created() {
        this.navList();
    },
    methods: {
        navList() {
            //导航
            fetch
                .post("/officialWebsiteMgr/resource/show_favor/classList", { pId: "00033" })
                .then((res) => {
                    if (res.code == 0) {
                        this.trackNav = res.data;
                        this.getList(res.data[0].id,0);
                    }
                });
        },
        getList(id,index) {
            //列表
            let sdata = {
                classId: id,
                name:"",
                startPage: this.currentPage,
                pageSize: 10,
            };
            fetch
                .post(
                    '/officialWebsiteMgr/resource/show_favor/indexList',
                    sdata
                )
                .then((res) => {
                    if (res.code == 0) {
                        this.trackList = res.data.list;
                        this.totalArt = res.data.totalCount;
                        this.trackActive = index;
                        this.classId = id;
                    }
                })
        },
        setMoment(time) {
            return moment(time).format('YY-MM-DD HH:mm:ss')
        },
        setUrl(url) {
            if(url.indexOf("http")==0){
                return url;
            }else{
                return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
            }
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList(this.classId,this.trackActive);
        },
        toDetail(id) {
            /*this.$router.push({
                name: 'footDetail',
                params: { id: id },
            })*/
            window.open("/#/footDetail/"+id, '_blank');
        },
    }
};
</script>
<style lang="scss" scoped>
.cont{
  width:1200px;
  margin:0 auto;
}
.cont>h2,.main>h2{
  font-size: 30px;
  color:#333333;
  text-align: center;
  margin:40px 0 20px;
  font-weight: normal;
}
.cont>h2 p,.main>h2 p{
  width:75px;
  height:3px;
  background:#4cb8df;
  margin:10px auto 0;
}
.well-known-saying{
  font-size: 12px;
  text-align: center;
  line-height:20px;
}
.footNav{
  height: 50px;
  line-height: 50px;
  text-align: center;
  min-width: 1200px;
  margin:20px 0;
}
.footNav span {
  font-size: 20px;
  color: #333;
  cursor: pointer;
  padding: 6px 45px;
  border-radius: 20px;
  margin: 0 10px;
  line-height: 50px;
}
.footNav span:hover{
  background:#ccc;
  color:#fff;
}
.warp .footNav .footActive{
  color: #fff;
  background-color: #02aae8;
}
.right{
  width:300px;
}
.rightCode{
  background:#fff;
  border-radius:8px;
  padding:20px 15px;
  font-size:16px;
  display: flex;
  display: -webkit-flex;
}
.rightCode p{
  line-height: 40px;
}
.rightCode p span{
  color:#127be8;
}
.rightCode img{
  width:85px;
  height:85px;
  margin-left:40px;
}
.advertising{
  background:#ccc;
  width:300px;
  height:300px;
  margin-top:20px;
  border-radius:8px;
}
</style>